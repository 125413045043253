html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
strong,
abbr,
acronym,
blockquote,
q,
cite,
ins,
del,
dfn,
a,
div,
span,
pre,
hr,
address,
br,
b,
i,
sub,
big,
small,
tt,
table,
tr,
caption,
thead,
tbody,
tfoot,
col,
colgroup,
form,
input,
label,
textarea,
button,
fieldset,
legend,
select,
option,
ul,
ol,
li,
dl,
dt,
dd,
code,
var,
samp,
img,
object,
param,
map,
area {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
header,
footer,
section,
aside,
figure,
figcaption,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
button {
  border: 0;
}
:focus {
  outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
input,
textarea {
  resize: none;
  user-select: text;
}
::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Druk Wide Cy';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Druk-Wide-Medium-Web.woff2") format('woff2');
}
@font-face {
  font-family: 'Druk Wide Cy';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Druk-Wide-Super-Web.woff2") format('woff2');
}
@font-face {
  font-family: 'Druk Wide Cy';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Druk-Wide-Bold-Web.woff2") format('woff2');
}
@font-face {
  font-family: 'Druk Wide Cy';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Druk-Wide-Heavy-Web.woff2") format('woff2');
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-item {
  transform: translateZ(0);
}
img {
  display: block;
}
button {
  background: transparent;
}
input,
textarea {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-appearance: none;
  border-radius: 0;
  color: rgba(255,255,255,0.45);
}
html,
body {
  background-color: #06081b;
  color: #fff;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
* {
  margin: 0;
  padding: 0;
}
header,
footer,
section {
  max-width: 100vw;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
}
section {
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;
  max-width: 100vw;
}
@media only screen and (min-width: 768px) {
  section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  section {
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
header {
  padding-top: 24px;
  padding-bottom: 32px;
  position: relative;
  max-width: 100vw;
}
@media only screen and (min-width: 768px) {
  header {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  header {
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
footer {
  padding-top: 32px;
}
@media only screen and (min-width: 768px) {
  footer {
    padding-top: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  footer {
    padding-top: 52px;
  }
}
.b-wrapper {
  width: 100%;
  display: table;
  height: 100%;
  padding-top: 56px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .b-wrapper {
    padding-top: 64px;
  }
}
.content {
  display: table-row;
  width: 100%;
  height: 100%;
}
.section {
  width: 100%;
}
.hidden {
  display: none !important;
}
.container {
  max-width: 1408px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.row {
  margin-bottom: 0;
}
.col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
span {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
}
strong {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: 700;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Druk Wide Cy';
  color: #fff;
  font-weight: 700;
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: #e8ac54 !important;
}
h1 {
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
}
@media only screen and (min-width: 480px) {
  h1 {
    font-size: 26px;
    line-height: 32px;
  }
}
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 42px;
    line-height: 52px;
  }
}
h2 {
  font-style: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 12px;
}
@media only screen and (min-width: 480px) {
  h2 {
    font-size: 26px;
    line-height: 32px;
  }
}
@media only screen and (min-width: 1200px) {
  h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }
}
h3 {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  line-height: 32px;
}
h5 {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 32px;
}
p {
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  color: #fff;
}
.description {
  color: rgba(255,255,255,0.65);
  margin-bottom: 26px;
  text-align: center;
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1200px) {
  .description {
    margin-bottom: 40px;
  }
}
.custom-list li {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}
.custom-list li:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
}
.btn-custom {
  min-width: 150px;
  padding: 12px 20px;
  border-radius: 10px;
  background: #e8ac54;
  color: #1a1a22;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
@media only screen and (min-width: 768px) {
  .btn-custom {
    padding: 16px 24px;
  }
}
.btn-custom:hover {
  background: #ffda90;
}
.btn-custom--middle {
  padding: 12px 20px !important;
  border-radius: 8px !important;
  font-size: 16px;
  line-height: 24px;
}
.btn-custom--small {
  padding: 10px 16px !important;
  border-radius: 8px !important;
  font-size: 14px;
  line-height: 20px;
}
.btn-custom--border {
  background: transparent !important;
  border: 1px solid rgba(255,255,255,0.12) !important;
  color: #fff !important;
}
.btn-custom--border:hover {
  border: 1px solid rgba(255,255,255,0.2) !important;
}
.btn-custom--gray {
  background: #22222f !important;
  color: #fff;
}
.btn-custom--gray:hover {
  background: #292a35 !important;
}
.language-select {
  position: relative;
}
.language-select img {
  width: 24px;
  height: 24px;
}
.language-select__dropdown-list {
  display: none;
  border-radius: 8px;
  background: var(--Cards, #151626);
  padding: 8px;
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.2);
  min-width: 224px;
}
.language-select__dropdown-list.active {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
}
.language-select__dropdown-item {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  padding: 12px 16px;
  transition: all 0.3s ease;
}
.language-select__dropdown-item:hover,
.language-select__dropdown-item.active {
  background-color: #22222f;
}
.language-select__dropdown-item:not(.active) {
  cursor: pointer;
}
.language-select__dropdown-item.active:after {
  content: '';
  display: block;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url("/img/check.svg") center no-repeat;
  background-size: cover;
}
.language-select__dropdown-item-image {
  margin-right: 12px;
}
.language-select__dropdown-item-text {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  color: #fff;
}
.language-select--full .language-select__dropdown-list.active {
  top: auto;
  bottom: 58px;
}
.language-select--full .language-select__dropdown-link {
  padding: 12px 16px;
  border-radius: 8px;
  background: #151626;
  display: flex;
  align-items: center;
  width: 180px;
}
.language-select--full .language-select__dropdown-link .language-select__dropdown-link-text {
  margin-left: 12px;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.language-select--full .language-select__dropdown-link .language-select__dropdown-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.language-select--full .language-select__dropdown-link.active .language-select__dropdown-icon {
  transform: rotate(-180deg);
}
.social-link {
  width: 32px;
  height: 32px;
  transition: all 0.3s ease;
}
.modal-overlay {
  background: rgba(21,21,21,0.75) !important;
  backdrop-filter: blur(8px) !important;
  opacity: 1 !important;
}
.modal {
  width: 100%;
  max-width: inherit;
  padding: 72px 16px 32px;
  max-height: 100%;
  top: 50% !important;
  transform: translateY(-50%) scaleX(0.8) scaleY(0.8) !important;
  border-radius: 0;
  height: 100%;
  background: #151626;
  box-shadow: 0px 28px 56px 0px rgba(0,0,0,0.8);
}
@media only screen and (min-width: 768px) {
  .modal {
    border-radius: 20px;
    max-width: 480px;
    max-height: 95%;
    height: auto;
    padding: 48px;
  }
}
.modal.open {
  top: 50% !important;
  transform: translateY(-50%) scaleX(1) scaleY(1) !important;
}
.modal.modal-table {
  max-width: 1024px;
}
@media only screen and (max-width: 1024px) {
  .modal.modal-table {
    max-height: 100%;
    border-radius: 0;
    height: 100%;
  }
}
.modal.modal-table .modal-table__btns-wrap {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
@media only screen and (min-width: 768px) {
  .modal.modal-table .modal-table__btns-wrap {
    margin-top: 32px;
  }
}
.modal .modal-content {
  padding: 0;
}
.modal__cross {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .modal__cross {
    top: 24px;
    right: 24px;
  }
}
.modal__title {
  margin-bottom: 32px;
}
.modal__main-title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}
.modal__main-text {
  color: rgba(255,255,255,0.65);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.theme-radio {
  margin-left: 10px;
  cursor: pointer;
}
@media only screen and (min-width: 1200px) {
  .theme-radio {
    margin-left: 12px;
  }
}
.theme-radio__content {
  position: relative;
  width: 45px;
  height: 24px;
  border-radius: 14px;
  background-color: #e8ac54;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) {
  .theme-radio__content {
    width: 52px;
    height: 28px;
    border-radius: 16px;
  }
}
.theme-radio__img,
.theme-radio__btn {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 1200px) {
  .theme-radio__img,
  .theme-radio__btn {
    width: 20px;
    height: 20px;
  }
}
.theme-radio__btn {
  z-index: 2;
  transition: all 0.3s ease;
}
.theme-radio__img {
  opacity: 0;
  transition: all 0.3s ease;
}
.theme-radio__img.active {
  opacity: 1;
}
.theme-radio__img--sun {
  left: 4px;
}
.theme-radio__img--moon {
  right: 4px;
}
.theme-radio.moon .theme-radio__btn {
  background-color: #06081b;
  left: 25px;
}
@media only screen and (min-width: 1200px) {
  .theme-radio.moon .theme-radio__btn {
    left: 28px;
  }
}
.theme-radio.sun .theme-radio__btn {
  background-color: #fff;
  left: 4px;
}
.tooltipped {
  position: relative;
  cursor: pointer;
}
.tooltipped .tooltipped-text {
  display: block;
  position: absolute;
  right: -270px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  background: #292a35;
  padding: 12px;
  color: rgba(255,255,255,0.65);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  opacity: 0;
  transition: all 0.3s ease;
  width: 260px;
  pointer-events: none;
}
.tooltipped .tooltipped-text:before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  left: -8px;
  background: url("/img/tooltip-arrow.svg") center no-repeat;
  background-size: cover;
}
.tooltipped:hover .tooltipped-text {
  opacity: 1;
}
.material-tooltip {
  display: none !important;
}
.table-container {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1px;
}
.table-container .table-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}
.table-container .table-row:nth-child(1) .row-item img {
  width: 32px;
  height: 20px;
  border-radius: 4px;
}
.table-container .table-row:nth-child(2) .row-item:first-child {
  border-top-left-radius: 16px;
}
.table-container .table-row:nth-child(2) .row-item:last-child {
  border-top-right-radius: 16px;
}
.table-container .table-row:last-child .row-item:first-child {
  border-bottom-left-radius: 16px;
}
.table-container .table-row:last-child .row-item:last-child {
  border-bottom-right-radius: 16px;
}
.table-container .table-row.heading .row-item {
  border: none;
  flex-wrap: wrap;
  background: transparent;
}
.table-container .table-row.heading .row-item p {
  font-weight: 700;
  width: 100%;
  margin-top: 4px;
  text-align: center;
}
.table-container .row-item {
  display: flex;
  flex: 1;
  padding: 12px;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  border: 1px solid #282833;
  margin-top: -1px;
  margin-left: -1px;
  position: relative;
  width: 120px;
  min-width: 120px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .table-container .row-item {
    width: auto;
    min-width: inherit;
  }
}
.table-container .row-item:first-child {
  background: #22222f;
  padding-right: 37px;
  min-width: 164px;
}
.table-container .row-item:first-child p {
  text-align: left;
  width: 100%;
}
.table-container .row-item p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.table-container .row-item .tooltipped {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}
.preloader-wrapper {
  display: none;
}
form.custom-form .select-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
form.custom-form .select-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url("/img/angle-down-small.svg") center no-repeat;
  background-size: cover;
  pointer-events: none;
  z-index: 2;
}
form.custom-form .select-wrapper .caret {
  display: none;
}
form.custom-form .select-wrapper .select-dropdown {
  padding: 16px;
  color: #fff;
  width: 100%;
  background-color: #22222f;
  border-radius: 8px;
}
form.custom-form .select-wrapper .dropdown-content {
  top: 60px !important;
  padding: 8px;
  border-radius: 8px;
  background: #22222f;
  box-shadow: 0px 6px 12px 0px rgba(0,0,0,0.2);
}
form.custom-form .select-wrapper .dropdown-content li {
  height: 48px;
  min-height: inherit;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
form.custom-form .select-wrapper .dropdown-content li span {
  padding: 12px 16px;
  color: rgba(255,255,255,0.4);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
form.custom-form .select-wrapper .dropdown-content li:hover {
  background-color: #151626 !important;
}
form.custom-form .select-wrapper .dropdown-content li:hover span {
  color: #fff;
}
form.custom-form .select-wrapper .dropdown-content li.selected {
  background-color: transparent;
}
form.custom-form .select-wrapper .dropdown-content li.selected:after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: url("/img/check.svg") center no-repeat;
  background-size: cover;
}
form.custom-form .form-group {
  margin-bottom: 12px;
}
form.custom-form .input-field {
  position: relative;
  margin: 0;
  display: flex;
  align-items: flex-end;
  border-radius: 8px;
}
form.custom-form .input-field input:-webkit-autofill,
form.custom-form .input-field input:-webkit-autofill:hover,
form.custom-form .input-field input:-webkit-autofill:focus,
form.custom-form .input-field textarea:-webkit-autofill,
form.custom-form .input-field textarea:-webkit-autofill:hover,
form.custom-form .input-field textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px #22222f inset !important;
}
form.custom-form .input-field .error {
  position: absolute;
  bottom: 0;
  left: 24px;
}
form.custom-form .input-field--textarea {
  height: auto;
}
form.custom-form .input-field textarea {
  height: 128px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  padding: 26px 16px 16px;
  background-color: #22222f;
  border-radius: 8px;
}
form.custom-form .input-field textarea:hover,
form.custom-form .input-field textarea:focus {
  background-color: #292a35;
}
form.custom-form .input-field input {
  margin-bottom: 0;
  border: none !important;
  box-shadow: none !important;
  height: 56px !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding: 26px 16px 8px 16px;
  background-color: #22222f;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
}
form.custom-form .input-field input:disabled {
  color: #fff;
}
form.custom-form .input-field input:hover,
form.custom-form .input-field input:focus {
  background-color: #292a35;
}
form.custom-form .input-field label {
  font-size: 16px;
  line-height: 24px;
  color: rgba(255,255,255,0.4) !important;
  transform: translateY(17px);
  padding-left: 16px;
  font-weight: 400;
}
form.custom-form .input-field label.active {
  transform: translateY(8px);
  font-size: 12px;
  line-height: 16px;
}
form.custom-form .form-error {
  position: static;
  margin-top: 4px;
  font-size: 12px;
  line-height: 12px;
  color: #ff4912;
  font-weight: 400;
}
form.custom-form .checkboxes-wrap {
  margin-top: 32px;
}
form.custom-form .checkboxes-wrap .checkbox {
  margin-bottom: 8px;
  position: relative;
}
form.custom-form .checkboxes-wrap .checkbox .form-error {
  bottom: -12px;
  left: 28px;
}
form.custom-form .checkboxes-wrap .checkbox__input {
  padding-left: 28px;
  top: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #151515;
}
form.custom-form .checkboxes-wrap .checkbox__input a {
  color: #ff4912;
  font-size: inherit;
  line-height: inherit;
}
form.custom-form .checkboxes-wrap [type="checkbox"]+span:not(.lever):before {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: 2px solid #ff4912;
  top: -5px;
  transform: none !important;
  left: 0 !important;
}
form.custom-form .checkboxes-wrap [type="checkbox"]:checked+span:not(.lever):before {
  background-color: #ff4912;
}
form.custom-form .checkboxes-wrap [type="checkbox"]:checked+span:not(.lever):after {
  width: 10px;
  height: 6px;
  border: 3px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  left: 5px;
  top: 1px;
}
form.custom-form .submit-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
form.custom-form .submit-btn-wrap .btn-custom {
  width: 100%;
}
.enter-y {
  transform: translateY(50px) rotate(2deg);
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.enter {
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.seen .enter {
  opacity: 1;
  transition-delay: 0.3s !important;
}
.seen .enter-y {
  opacity: 1;
  transform: translate(0) scale(1) rotate(0);
}
.seen .delay-2 {
  transition-delay: 0.1s !important;
}
.seen .delay-3 {
  transition-delay: 0.15s !important;
}
.seen .delay-4 {
  transition-delay: 0.2s !important;
}
.seen .delay-5 {
  transition-delay: 0.25s !important;
}
.seen .delay-6 {
  transition-delay: 0.3s !important;
}
.seen .delay-7 {
  transition-delay: 0.35s !important;
}
.seen .delay-8 {
  transition-delay: 0.4s !important;
}
.seen .delay-9 {
  transition-delay: 0.45s !important;
}
.rotate-card {
  opacity: 0;
  transform: rotateY(90deg);
  transition: all 0.3s ease;
}
.seen .rotate-card {
  opacity: 1;
  transform: rotateY(0);
}
.seen .rotate-card:nth-child(1) {
  transition-delay: 0.3s !important;
}
.seen .rotate-card:nth-child(2) {
  transition-delay: 0.6s !important;
}
.seen .rotate-card:nth-child(3) {
  transition-delay: 0.9s !important;
}
.seen .rotate-card:nth-child(4) {
  transition-delay: 1.2s !important;
}
.seen .rotate-card:nth-child(5) {
  transition-delay: 1.5s !important;
}
.seen .rotate-card:nth-child(6) {
  transition-delay: 1.8s !important;
}
.seen .rotate-card:nth-child(7) {
  transition-delay: 2.1s !important;
}
.seen .rotate-card:nth-child(8) {
  transition-delay: 2.4s !important;
}
.seen .rotate-card:nth-child(9) {
  transition-delay: 2.7s !important;
}
.seen .rotate-card:nth-child(10) {
  transition-delay: 3s !important;
}
.seen .rotate-card:nth-child(11) {
  transition-delay: 3.3s !important;
}
.seen .rotate-card:nth-child(12) {
  transition-delay: 3.6s !important;
}
.dropdown-card {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.3s ease;
}
.seen .dropdown-card {
  opacity: 1;
  transform: translateY(0);
}
.seen .dropdown-card:nth-child(1) {
  transition-delay: 0.3s !important;
}
.seen .dropdown-card:nth-child(2) {
  transition-delay: 0.6s !important;
}
.seen .dropdown-card:nth-child(3) {
  transition-delay: 0.9s !important;
}
.seen .dropdown-card:nth-child(4) {
  transition-delay: 1.2s !important;
}
.seen .dropdown-card:nth-child(5) {
  transition-delay: 1.5s !important;
}
.seen .dropdown-card:nth-child(6) {
  transition-delay: 1.8s !important;
}
.seen .dropdown-card:nth-child(7) {
  transition-delay: 2.1s !important;
}
.seen .dropdown-card:nth-child(8) {
  transition-delay: 2.4s !important;
}
.seen .dropdown-card:nth-child(9) {
  transition-delay: 2.7s !important;
}
.seen .dropdown-card:nth-child(10) {
  transition-delay: 3s !important;
}
.seen .dropdown-card:nth-child(11) {
  transition-delay: 3.3s !important;
}
.seen .dropdown-card:nth-child(12) {
  transition-delay: 3.6s !important;
}
.dropright-card {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}
.seen .dropright-card {
  opacity: 1;
  transform: translateX(0);
}
.seen .dropright-card:nth-child(1) {
  transition-delay: 0.3s !important;
}
.seen .dropright-card:nth-child(2) {
  transition-delay: 0.6s !important;
}
.seen .dropright-card:nth-child(3) {
  transition-delay: 0.9s !important;
}
.seen .dropright-card:nth-child(4) {
  transition-delay: 1.2s !important;
}
.seen .dropright-card:nth-child(5) {
  transition-delay: 1.5s !important;
}
.seen .dropright-card:nth-child(6) {
  transition-delay: 1.8s !important;
}
.seen .dropright-card:nth-child(7) {
  transition-delay: 2.1s !important;
}
.seen .dropright-card:nth-child(8) {
  transition-delay: 2.4s !important;
}
.seen .dropright-card:nth-child(9) {
  transition-delay: 2.7s !important;
}
.seen .dropright-card:nth-child(10) {
  transition-delay: 3s !important;
}
.seen .dropright-card:nth-child(11) {
  transition-delay: 3.3s !important;
}
.seen .dropright-card:nth-child(12) {
  transition-delay: 3.6s !important;
}
.error {
  color: #ff4912;
  pointer-events: none;
}
#chat-widget-container {
  bottom: 8px !important;
  right: 8px !important;
  z-index: 900 !important;
}
@media only screen and (min-width: 768px) {
  #chat-widget-container {
    bottom: 22px !important;
    right: 22px !important;
  }
}
#chat-widget-container:after {
  content: '';
  display: block;
  position: absolute;
  background: url("/img/livechat.svg") center no-repeat !important;
  background-size: cover !important;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  width: 46px;
  height: 46px;
}
@media only screen and (min-width: 768px) {
  #chat-widget-container:after {
    bottom: 10px;
    right: 10px;
    width: 60px;
    height: 60px;
  }
}
#chat-widget-container iframe {
  position: relative;
  z-index: 4;
}
#chat-widget-minimized {
  position: relative;
  z-index: 2;
  opacity: 0;
}
.intercom-chat {
  cursor: pointer;
}
.gdpr-cookie-notice {
  bottom: 20px;
  backdrop-filter: blur(20px);
  background: rgba(46,46,59,0.4);
  border-radius: 8px;
  margin: 0 auto;
  padding: 12px 24px 12px 44px;
  width: auto;
  max-width: 768px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: inherit;
  justify-content: space-between;
  transform: translateY(200%);
  z-index: 100000000000000 !important;
}
@media only screen and (max-width: 800px) {
  .gdpr-cookie-notice {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.gdpr-cookie-notice:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  width: 20px;
  height: 20px;
  background: url("/img/cookie.svg") center no-repeat;
  background-size: cover;
}
.gdpr-cookie-notice .gdpr-cookie-notice-nav-item-btn {
  color: #fff !important;
}
.gdpr-cookie-notice .gdpr-cookie-notice-description {
  color: rgba(255,255,255,0.65);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 0;
  text-align: left;
}
.gdpr-cookie-notice .gdpr-cookie-notice-nav-item-btn {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
}
.gdpr-cookie-notice .gdpr-cookie-notice-nav {
  width: auto;
  margin-top: 0;
  margin-left: 16px;
}
.body--moon .moon {
  display: block !important;
}
.body--moon .sun {
  display: none !important;
}
.body--moon .gdpr-cookie-notice .gdpr-cookie-notice-nav-item-btn {
  color: #1a1a22 !important;
}
.body--sun {
  background-color: #f1f2f4;
}
.body--sun .moon {
  display: none !important;
}
.body--sun .sun {
  display: block !important;
}
.body--sun #chat-widget-container:after {
  background: url("/img/livechat-w.svg") center no-repeat !important;
  background-size: cover !important;
}
.body--sun .tooltipped .tooltipped-text:before {
  background: url("/img/tooltip-arrow-w.svg") center no-repeat;
  background-size: cover;
}
.body--sun form.custom-form .select-wrapper:after {
  background: url("/img/angle-down-small-w.svg") center no-repeat;
  transform: translateY(-50%) rotate(-180deg);
}
.body--sun .tooltipped .tooltipped-text {
  box-shadow: 0 6px 18px 0 rgba(0,0,0,0.4);
}
.body--sun .btn-custom {
  color: #fff;
}
.body--sun .btn-custom--border {
  border: 1px solid rgba(26,26,34,0.4) !important;
  color: #1a1a22 !important;
}
.body--sun .modal__cross circle {
  fill: #f5f5f5;
}
.body--sun .modal__cross path {
  fill: #282833;
  fill-opacity: 1;
}
.body--sun .table-container .row-item:first-child {
  background: #f5f5f5;
}
.body--sun .table-container .table-row:first-child .row-item {
  background: transparent;
}
.body--sun .table-container .row-item {
  border: 1px solid rgba(26,26,34,0.12);
}
.body--sun .tooltipped .tooltipped-text {
  background: #f5f5f5;
  color: #282833;
}
.body--sun form.custom-form .input-field label {
  color: rgba(26,26,34,0.65) !important;
}
.body--sun .top-menu,
.body--sun .top-menu.not-top {
  background-color: #fff;
}
.body--sun form.custom-form .select-wrapper .dropdown-content,
.body--sun .modal,
.body--sun .language-select--full .language-select__dropdown-link,
.body--sun .s-join__block,
.body--sun .s-statuses__block,
.body--sun .language-select__dropdown-list,
.body--sun .s-how__block {
  background: #fff;
}
.body--sun .language-select__dropdown-item.active,
.body--sun .language-select__dropdown-item:hover,
.body--sun form.custom-form .input-field input:focus,
.body--sun form.custom-form .input-field input:hover,
.body--sun form.custom-form .input-field input,
.body--sun form.custom-form .input-field textarea:focus,
.body--sun form.custom-form .input-field textarea:hover,
.body--sun form.custom-form .input-field textarea,
.body--sun form.custom-form .select-wrapper .dropdown-content .selected,
.body--sun form.custom-form .select-wrapper .dropdown-content .selected:hover,
.body--sun form.custom-form .select-wrapper .dropdown-content li:hover {
  background-color: #f5f5f5 !important;
}
.body--sun .top-menu .top-menu-link:not(:hover),
.body--sun .sidenav .sidenav-content .top-menu-link:not(:hover),
.body--sun .sidenav--ar .sidenav-content .top-menu-link:not(:hover),
.body--sun .language-select__dropdown-item-text,
.body--sun h1,
.body--sun h2,
.body--sun h4,
.body--sun h6,
.body--sun .custom-list li,
.body--sun .s-benefits__item-title,
.body--sun .s-how__block-title,
.body--sun .s-join__block-title,
.body--sun .footer-menu-link:not(:hover),
.body--sun .language-select--full .language-select__dropdown-link .language-select__dropdown-link-text,
.body--sun .modal__title,
.body--sun .table-container .row-item p,
.body--sun .modal__main-title,
.body--sun form.custom-form .input-field input,
.body--sun form.custom-form .select-wrapper .dropdown-content li span,
.body--sun form.custom-form .select-wrapper .dropdown-content li:hover,
.body--sun form.custom-form .input-field textarea {
  color: #1a1a22 !important;
}
.body--sun .custom-list li:before {
  background-color: #1a1a22;
}
.body--sun .footer__copyright-text,
.body--sun .s-statuses__block-content-title {
  color: rgba(26,26,34,0.4);
}
.body--sun .btn-custom--gray {
  background: #e8ac54 !important;
}
.body--sun .modal {
  box-shadow: 0 28px 56px 0 rgba(0,0,0,0.4);
}
.body--sun .footer__content {
  border-bottom: 2px solid #fff;
}
.body--sun .modal__main-text,
.body--sun .s-join__block-text,
.body--sun .s-how__block-text,
.body--sun .s-benefits__item-text,
.body--sun .description {
  color: #282833;
}
.body--sun .sidenav .sidenav-header,
.body--sun .sidenav--ar .sidenav-header,
.body--sun .sidenav,
.body--sun .sidenav--ar {
  background-color: #fff;
}
.body--sun .modal-overlay,
.body--sun .sidenav-overlay {
  background-color: rgba(241,242,244,0.8) !important;
}
.body--sun .footer__social-link:not(:hover) svg path,
.body--sun .sidenav-cross svg path {
  fill: #6a6a6f;
  fill-opacity: 1;
}
.body--ar .theme-radio {
  margin-right: 10px;
  margin-left: 0;
}
@media only screen and (min-width: 1200px) {
  .body--ar .theme-radio {
    margin-right: 12px;
  }
}
@media only screen and (min-width: 768px) {
  .body--ar .header__title {
    text-align: right;
  }
}
@media only screen and (min-width: 960px) {
  .body--ar .top-menu .brand-logo {
    margin-left: 32px;
    margin-right: 0;
  }
}
.body--ar .language-select__dropdown-list.active {
  left: 0;
  right: auto;
}
.body--ar .language-select__dropdown-item-image {
  margin-left: 12px;
  margin-right: 0;
}
.body--ar .language-select__dropdown-item.active:after {
  right: auto;
  left: 8px;
}
.body--ar .top-menu__hamburger {
  margin-right: 0 !important;
  margin-left: 18px !important;
}
.body--ar .sidenav .sidenav-header .sidenav-cross,
.body--ar .sidenav--ar .sidenav-header .sidenav-cross {
  right: auto;
  left: 16px;
}
.body--ar .custom-list li {
  padding-left: 0;
  padding-right: 20px;
}
.body--ar .custom-list li:before {
  left: auto;
  right: 0;
}
@media only screen and (min-width: 1200px) {
  .body--ar .s-benefits__bg {
    left: -174px;
    right: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .body--ar .s-benefits__bg {
    left: -60px;
  }
}
@media only screen and (min-width: 1680px) {
  .body--ar .s-benefits__bg {
    left: 0;
  }
}
.body--ar .s-how__block-content,
.body--ar .s-benefits__item-content {
  margin-left: 0;
  margin-right: 24px;
}
.body--ar .language-select--full .language-select__dropdown-link .language-select__dropdown-icon {
  right: auto;
  left: 12px;
}
.body--ar .language-select__dropdown-link-text {
  margin-left: 0;
  margin-right: 12px;
}
.body--ar .gdpr-cookie-notice {
  padding: 12px 44px 12px 24px;
}
.body--ar .gdpr-cookie-notice:before {
  left: auto;
  right: 16px;
}
.body--ar .gdpr-cookie-notice .gdpr-cookie-notice-nav {
  margin-left: 0;
  margin-right: 16px;
}
.body--ar .gdpr-cookie-notice .gdpr-cookie-notice-description {
  padding-right: 0;
  padding-left: 16px;
}
.body--ar .modal__cross {
  left: 16px;
  right: auto;
}
@media only screen and (min-width: 768px) {
  .body--ar .modal__cross {
    left: 24px;
  }
}
.body--ar .table-container .table-row:nth-child(2) .row-item:last-child {
  border-top-left-radius: 16px;
  border-top-right-radius: 0;
}
.body--ar .table-container .table-row:last-child .row-item:last-child {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 0;
}
.body--ar .table-container .table-row:nth-child(2) .row-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 16px;
}
.body--ar .table-container .table-row:last-child .row-item:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 16px;
}
.body--ar .table-container .row-item:first-child {
  padding-right: 12px;
  padding-left: 37px;
}
.body--ar .table-container .row-item:first-child p {
  text-align: right;
}
.body--ar .table-container .row-item .tooltipped {
  left: 12px;
  right: auto;
}
.body--ar .tooltipped .tooltipped-text {
  right: auto;
  left: -270px;
}
.body--ar .tooltipped .tooltipped-text:before {
  right: -8px;
  left: auto;
  transform: scaleX(-1) translateY(-50%);
}
.body--ar form.custom-form .input-field label {
  padding-right: 16px;
  padding-left: 0;
  right: 0;
  left: auto;
  pointer-events: none;
}
.body--ar form.custom-form .select-wrapper:after {
  right: auto;
  left: 12px;
}
.body--ar form.custom-form .select-wrapper .dropdown-content li span {
  text-align: right;
}
.body--ar form.custom-form .select-wrapper .dropdown-content li.selected:after {
  right: auto;
  left: 8px;
}
.body--ar .s-statuses__blocks-wrap {
  margin-right: -6px;
}
.body--ar .s-benefits__list {
  margin-right: -16px;
}
.body--ar .s-join__blocks-wrap,
.body--ar .s-how__blocks-wrap {
  margin-right: -8px;
}
.body--ar .s-statuses__block-img {
  transform: translate(-50%, -50%) scaleX(-1);
}
.body--ar .s-benefits__bg {
  transform: scaleX(-1) translateX(50%);
}
@media only screen and (min-width: 1200px) {
  .body--ar .s-benefits__bg {
    transform: scaleX(-1);
  }
}
.body--ar .s-statuses:before {
  top: 0;
  bottom: auto;
}
.body--ar .s-statuses:after {
  top: auto;
  bottom: -400px;
}
.top-menu {
  height: auto;
  line-height: inherit;
  background-color: #151626;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  box-shadow: none;
  transition: all 0.3s ease;
  padding: 16px 0;
  width: 100vw;
}
@media only screen and (min-width: 960px) {
  .top-menu {
    padding: 18px 0;
  }
}
.top-menu.not-top {
  background-color: #151626;
  padding: 16px 0;
}
.top-menu .nav-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.top-menu .nav-wrapper__links {
  display: flex;
  align-items: center;
}
.top-menu .nav-wrapper__part {
  display: flex;
  align-items: center;
}
.top-menu .brand-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
}
@media only screen and (min-width: 960px) {
  .top-menu .brand-logo {
    position: static;
    margin-right: 32px;
    transform: none;
  }
}
.top-menu .brand-logo img {
  max-width: 100%;
  width: 130px;
}
.top-menu .anqors-wrap {
  margin-right: 4px;
  display: flex;
  align-items: center;
}
.top-menu .top-menu-link {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s ease;
  padding-left: 16px;
  padding-right: 16px;
  background: transparent !important;
}
.top-menu .top-menu-link:hover {
  color: #e8ac54;
}
.top-menu__hamburger {
  line-height: inherit !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
  width: 24px !important;
  height: 24px !important;
}
.top-menu__hamburger img {
  max-width: 100%;
  width: 24px;
  height: 24px;
}
.desctop-menu {
  display: none;
}
@media only screen and (min-width: 960px) {
  .desctop-menu {
    display: block;
  }
}
.mobile-menu {
  display: block;
}
.mobile-menu__right-part,
.mobile-menu__left-part {
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 960px) {
  .mobile-menu {
    display: none;
  }
}
.sidenav--ar,
.sidenav {
  background-color: #151626;
  width: 300px;
}
.sidenav--ar .sidenav-header,
.sidenav .sidenav-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #151626;
  padding: 32px;
  position: relative;
}
.sidenav--ar .sidenav-header .sidenav-cross,
.sidenav .sidenav-header .sidenav-cross {
  position: absolute;
  top: 16px;
  right: 16px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.sidenav--ar .sidenav-header .sidenav-cross svg,
.sidenav .sidenav-header .sidenav-cross svg {
  width: 24px;
  height: 24px;
}
.sidenav--ar .sidenav-content,
.sidenav .sidenav-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sidenav--ar .sidenav-content .nav-wrapper__links,
.sidenav .sidenav-content .nav-wrapper__links,
.sidenav--ar .sidenav-content .anqors-wrap,
.sidenav .sidenav-content .anqors-wrap {
  width: 100%;
}
.sidenav--ar .sidenav-content li,
.sidenav .sidenav-content li {
  line-height: inherit;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.sidenav--ar .sidenav-content .top-menu-link,
.sidenav .sidenav-content .top-menu-link {
  height: auto;
  margin: 12px 0;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  background: transparent !important;
}
.mobile-logo {
  display: none;
}
.modal-signup .custom-form {
  margin-top: 32px;
}
.modal-signup .input--contactway {
  display: none !important;
  width: 100%;
}
@media only screen and (min-width: 480px) {
  .modal-signup .input--contactway {
    width: calc(100% - 167px);
  }
}
.modal-signup .input--contactway.active {
  display: flex !important;
}
.modal-signup .form-group--contactway {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
@media only screen and (min-width: 480px) {
  .modal-signup .form-group--contactway {
    flex-wrap: nowrap;
  }
}
.modal-signup .input-field--contactway {
  width: 100%;
}
@media only screen and (min-width: 480px) {
  .modal-signup .input-field--contactway {
    width: 155px;
  }
}
.modal-signup .input-field--contactway .select-dropdown li.selected span {
  color: #fff !important;
}
.modal-signup .input-field--contactway .select-dropdown li.selected:after {
  display: none !important;
}
.modal-thanks__img-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.modal-thanks__btn-wrap {
  margin-top: 32px;
}
.modal-thanks__btn-wrap .btn-custom {
  width: 100%;
}
.footer__content {
  position: relative;
  padding-bottom: 32px;
  border-bottom: 2px solid #22222f;
}
.footer__logo-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.footer__logo {
  width: 130px;
}
.footer__main-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.footer__social-link {
  position: relative;
  z-index: 3;
}
.footer__social-link--inst:hover path {
  fill: #e8ac54;
  fill-opacity: 1;
}
.footer .footer-menu-link {
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgba(255,255,255,0.65);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin: 8px 24px;
}
.footer .footer-menu-link:hover {
  color: #e8ac54;
}
.footer__links-wrap {
  margin-top: 24px;
  display: flex;
  position: static;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  left: 50%;
  top: 50%;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-right: -24px;
  order: 3;
}
@media only screen and (min-width: 960px) {
  .footer__links-wrap {
    margin-top: 0;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
.footer__copyright {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 80px;
}
.footer__copyright-text {
  color: rgba(255,255,255,0.4);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.header {
  position: relative;
}
.header__bg-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.header__content-wrap {
  position: relative;
  padding: 224px 16px 24px;
  border-radius: 16px;
  overflow: hidden;
}
@media only screen and (min-width: 480px) {
  .header__content-wrap {
    padding: 304px 16px 24px;
  }
}
@media only screen and (min-width: 768px) {
  .header__content-wrap {
    padding: 304px 40px 40px;
  }
}
@media only screen and (min-width: 1200px) {
  .header__content-wrap {
    padding: 80px 48px 160px;
  }
}
.header__content {
  position: relative;
  z-index: 2;
}
.header__title {
  margin-bottom: 16px;
  text-align: center;
  color: #1a1a22;
}
@media only screen and (min-width: 768px) {
  .header__title {
    margin-bottom: 20px;
    text-align: left;
  }
}
.header__text {
  text-align: center;
  color: #1a1a22;
}
@media only screen and (min-width: 768px) {
  .header__text {
    text-align: left;
    max-width: 420px;
  }
}
@media only screen and (min-width: 1200px) {
  .header__text {
    max-width: 664px;
  }
}
.header__btns-wrap {
  display: flex;
  align-items: center;
  margin-top: 32px;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .header__btns-wrap {
    justify-content: flex-start;
    margin-top: 48px;
  }
}
.s-statuses {
  position: relative;
}
.s-statuses:before {
  content: '';
  display: none;
  width: 896px;
  height: 896px;
  position: absolute;
  bottom: -400px;
  left: -500px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255,10,10,0.14) 0%, rgba(180,0,75,0) 100%);
  border-radius: 50%;
}
@media only screen and (min-width: 1200px) {
  .s-statuses:before {
    display: block;
  }
}
.s-statuses:after {
  content: '';
  display: none;
  width: 896px;
  height: 896px;
  position: absolute;
  top: 0;
  right: -500px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0,255,240,0.12) 0%, rgba(17,77,148,0) 100%);
  border-radius: 50%;
}
@media only screen and (min-width: 1200px) {
  .s-statuses:after {
    display: block;
  }
}
.s-statuses__content {
  position: relative;
  z-index: 2;
}
.s-statuses__description {
  max-width: 620px;
}
.s-statuses__blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 12px);
  margin-left: -6px;
}
@media only screen and (min-width: 1200px) {
  .s-statuses__blocks-wrap {
    width: calc(100% + 16px);
    margin-left: -8px;
  }
}
.s-statuses__block {
  width: calc(100% / 1 - 12px);
  margin: 6px;
  border-radius: 16px;
  background: #151626;
  backdrop-filter: blur(5px);
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .s-statuses__block {
    width: calc(100% / 2 - 12px);
  }
}
@media only screen and (min-width: 1200px) {
  .s-statuses__block {
    width: calc(100% / 4 - 16px);
    margin: 8px;
  }
}
.s-statuses__block:nth-child(4) .s-statuses__block-title,
.s-statuses__block:nth-child(4) .s-statuses__block-price-text {
  color: #fff;
}
.s-statuses__block-top {
  padding: 24px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  height: 200px;
  display: flex;
}
.s-statuses__block-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.s-statuses__block-top-content {
  position: relative;
  z-index: 2;
  width: 100%;
}
.s-statuses__block-pretitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #1a1a22;
}
.s-statuses__block-title {
  font-family: 'Druk Wide Cy';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  text-transform: uppercase;
  color: #1a1a22;
}
.s-statuses__block-bonus-title {
  margin-top: 4px;
  color: #ff6214;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px; /* 160% */
  letter-spacing: 12.5px;
}
.s-statuses__block-subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.s-statuses__block-price-text {
  color: #1a1a22;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.s-statuses__block-price-number {
  font-family: 'Druk Wide Cy';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.s-statuses__block-price-number--0 {
  color: #1a1a22;
}
.s-statuses__block-price-number--1 {
  color: #1a1a22;
}
.s-statuses__block-price-number--2 {
  color: #1a1a22;
}
.s-statuses__block-price-number--3 {
  color: #fff;
}
.s-statuses__block-content {
  position: relative;
  padding: 20px 20px 70px 20px;
}
.s-statuses__block-content-title {
  color: rgba(255,255,255,0.4);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.s-statuses__btns-wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 28px);
  margin-left: -6px;
  left: 20px;
  bottom: 14px;
  position: absolute;
}
.s-statuses__btns-wrap .btn-custom {
  margin: 6px;
  width: calc(100% / 2 - 12px);
  min-width: 100px;
  text-align: center;
  height: 42px;
  line-height: 16px;
}
.s-benefits {
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .s-benefits {
    padding-bottom: 500px;
  }
}
@media only screen and (max-width: 479px) {
  .s-benefits {
    padding-bottom: 300px;
  }
}
.s-benefits__description {
  max-width: 782px;
}
.s-benefits__bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 334px;
}
@media only screen and (min-width: 480px) {
  .s-benefits__bg {
    height: 534px;
  }
}
@media only screen and (min-width: 1200px) {
  .s-benefits__bg {
    left: auto;
    right: -174px;
    transform: none;
  }
}
@media only screen and (min-width: 1440px) {
  .s-benefits__bg {
    height: 580px;
    bottom: 0;
    right: -60px;
  }
}
@media only screen and (min-width: 1680px) {
  .s-benefits__bg {
    right: 0;
  }
}
.s-benefits__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 32px);
  margin-left: -16px;
  z-index: 2;
}
@media only screen and (min-width: 1200px) {
  .s-benefits__list {
    max-width: 712px;
  }
}
.s-benefits__item {
  margin: 12px 16px;
  display: flex;
  align-items: flex-start;
  width: calc(100% / 1 - 32px);
}
@media only screen and (min-width: 768px) {
  .s-benefits__item {
    width: calc(100% / 2 - 32px);
    margin: 16px 16px;
  }
}
@media only screen and (min-width: 1200px) {
  .s-benefits__item {
    margin: 24px 16px;
  }
}
.s-benefits__item-content {
  margin-left: 24px;
}
.s-benefits__item-img {
  width: 60px;
  height: 60px;
}
.s-benefits__item-title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  margin-bottom: 8px;
}
.s-benefits__item-text {
  color: rgba(255,255,255,0.65);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.s-how__blocks-wrap {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin-left: -8px;
}
.s-how__block {
  width: calc(100% / 1 - 16px);
  margin: 6px 8px;
  padding: 24px 20px;
  border-radius: 16px;
  background: #151626;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: flex-start;
}
@media only screen and (min-width: 768px) {
  .s-how__block {
    margin: 8px;
  }
}
@media only screen and (min-width: 1200px) {
  .s-how__block {
    width: calc(100% / 3 - 16px);
  }
}
.s-how__block a {
  color: #e8ac54;
  cursor: pointer;
}
.s-how__block-img {
  width: 60px;
  height: 60px;
}
.s-how__block-content {
  margin-left: 24px;
}
.s-how__block-title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 8px;
}
.s-how__block-text {
  color: rgba(255,255,255,0.65);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.s-how__btns-wrap {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
@media only screen and (min-width: 768px) {
  .s-how__btns-wrap {
    margin-top: 24px;
  }
}
.s-join {
  position: relative;
}
.s-join__blocks-wrap {
  width: calc(100% + 16px);
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
}
.s-join__block {
  position: relative;
  width: calc(100% / 1 - 16px);
  margin: 8px;
  padding: 32px 24px 80px 24px;
  border-radius: 12px;
  background: #151626;
}
@media only screen and (min-width: 768px) {
  .s-join__block {
    width: calc(100% / 2 - 16px);
    padding: 48px 48px 96px 48px;
  }
}
.s-join__block-img-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.s-join__block-img {
  width: 80px;
  height: 80px;
}
.s-join__block-title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}
.s-join__block-text {
  color: rgba(255,255,255,0.65);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}
@media only screen and (min-width: 768px) {
  .s-join__block-text {
    max-width: inherit;
  }
}
.s-join__block-btns-wrap {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (min-width: 768px) {
  .s-join__block-btns-wrap {
    bottom: 48px;
  }
}
.s-seo__text-wrap {
  position: relative;
  height: 96px;
  overflow: hidden;
}
.s-seo__text-wrap:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 64px;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(6,8,28,0) 0%, #06081c 100%);
}
.s-seo__title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
}
.s-seo__text {
  color: rgba(255,255,255,0.65);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 24px;
}
.s-seo__more-btn {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}
.s-seo__more-btn .less-text {
  display: none;
}
.s-seo__content.active .s-seo__text-wrap {
  height: auto;
}
.s-seo__content.active .s-seo__text-wrap:after {
  display: none;
}
.s-seo__content.active .s-seo__more-btn .more-text {
  display: none;
}
.s-seo__content.active .s-seo__more-btn .less-text {
  display: block;
}
.s-seo__content.active .s-seo__more-btn img {
  transform: rotate(-180deg);
  width: 24px;
  height: 24px;
}
.s-luxury {
  position: relative;
}
.s-luxury__cards-wrap {
  width: calc(100% + 16px);
  margin-left: -8px;
  display: flex;
  flex-wrap: wrap;
}
.s-luxury__card {
  margin: 8px;
  width: calc(100% / 1 - 16px);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  position: relative;
}
@media only screen and (min-width: 480px) {
  .s-luxury__card {
    width: calc(100% / 2 - 16px);
    height: 300px;
  }
}
@media only screen and (min-width: 1200px) {
  .s-luxury__card {
    width: calc(100% / 4 - 16px);
  }
}
.s-luxury__card-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}
